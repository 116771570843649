import React, { useEffect } from 'react';

import Navbar from './Navbar'; // Import existing Navbar
import Footer from './footer'; // Import existing Footer
import './Main.css';
import './ThankYou.css'; // Import CSS for styling
import backgroundImage from './Images/thankyou.JPG'; // Replace this with your image

function ThankYou() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top when this page is loaded
    }, []);

    return (
        <>
            <Navbar />
            <div
                className="thankyou-container"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    minHeight: '100vh',
                }}
            >
                <div className="thankyou-message">
                    <div className="thankyou-content">
                        <h1 className="animated-title">Thank You for Reaching Out!</h1>
                        <p className="animated-description">
                            Your inquiry has been received, and our team is excited to assist you in planning your perfect desert getaway.
                        </p>
                        <p className="animated-description">
                            A representative from Desert Pearl Resort will contact you shortly with all the details and assistance you need.
                        </p>
                        <p className="animated-description">
                            Until then, feel free to explore our <a href="/packages" className="highlight-link">exclusive packages</a> and discover the beauty of Jaisalmer.
                        </p>
                        <a href="/" className="btn-home">Return to Home</a>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ThankYou;