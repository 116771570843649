import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar1';
import Footer from './footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './LandingPage.css';
import galleryImage1 from './Images/img2.JPG';
import galleryImage2 from './Images/hey.JPG';
import galleryImage3 from './Images/Slide2.JPG';
import galleryImage4 from './Images/campfire.jpg';
import p1 from './Images/pck1.jpeg';
import p2 from './Images/pck2.jpg';
import p3 from './Images/pck3.jpg';

// Dynamic array for gallery images
const galleryImages = [
    { src: galleryImage1, caption: 'Luxury Tent' },
    { src: galleryImage2, caption: 'Cultural Dance' },
    { src: galleryImage3, caption: 'Desert View' },
    { src: galleryImage4, caption: 'Campfire' },
];

const LandingPage = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('message', message);

        try {
            const response = await fetch('/backend/submit_enquiry.php', {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            if (data.success) {
                // Redirect to the thank-you page
                navigate('/thankyou');
            } else {
                alert('Failed to submit your query. Please try again.');
            }
        } catch (error) {
            console.error("Error submitting the query:", error);
            alert('An error occurred while submitting your query. Please try again later.');
        }
    };

    // Initialize AOS (for animations)
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    // Scroll detection for navbar behavior
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50); // Add scrolled class after 50px
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="landing-page">
            {/* Navigation Bar */}
            <div className={`navbar-container ${isScrolled ? 'scrolled' : ''}`}>
                <Navbar />
            </div>

            {/* Hero Section */}
            <section className="hero">
                <div className="hero-content">
                    <h1>Welcome to Desert Pearl Resort</h1>
                    <p>Luxury, tradition, and tranquility in the heart of the desert.</p>
                    <button
                        className="cta-button"
                        onClick={() => {
                            const formElement = document.getElementById('contact-form');
                            formElement.scrollIntoView({ behavior: 'smooth' });
                        }}
                    >
                        Book Now
                    </button>
                </div>
            </section>

            {/* About Section */}
            <section className="about">
                <div className="about-header">
                    <h2>About Us</h2>
                    <p>
                        Nestled in the golden dunes of Jaisalmer, Desert Pearl Resort offers a one-of-a-kind blend of modern luxury and traditional Rajasthani culture. 
                        Experience royal hospitality, authentic cuisine, and breathtaking desert safaris. Our resort features elegant accommodations, a relaxing spa, 
                        cultural performances, and thrilling adventure activities.
                    </p>
                </div>
                <div className="about-cards">
                    {[
                        {
                            title: 'Luxurious Accommodations',
                            description: 'Stay in opulent tents and suites designed for ultimate comfort.',
                        },
                        {
                            title: 'Cultural Experiences',
                            description: 'Enjoy traditional Rajasthani folk dances and music every evening.',
                        },
                        {
                            title: 'Adventure Awaits',
                            description: 'Explore the dunes with desert safaris, camel rides, and star gazing.',
                        },
                    ].map((card, index) => (
                        <div className="about-card" data-aos="fade-up" key={index}>
                            <h3>{card.title}</h3>
                            <p>{card.description}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Gallery Section */}
            <section className="gallery">
                <h2>Gallery</h2>
                <div className="gallery-grid">
                    {galleryImages.map((image, index) => (
                        <div className="gallery-card" key={index} data-aos="zoom-in">
                            <img src={image.src} alt={image.caption} />
                            <p>{image.caption}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Packages Section */}
            <section className="packages">
                <h2>Our Exclusive Packages</h2>
                <div className="package-cards">
                    {/* <!-- Package 1 --> */}
                    <div className="package-card" data-aos="fade-up">
                        <h3>A Night in the Desert Pearl</h3>
                        <p className="package-price">Rs. 3050 / Couple (1 Night Stay at Camp)</p>
                        <img src={p1} alt="A Night in the Desert Pearl" />
                        <ul className="package-details">
                            <li>Traditional welcome with Aarti & Tikka.</li>
                            <li>1 Night Stay at Camp.</li>
                            <li>One Camel Safari in the evening safari on dunes.</li>
                            <li>Buffet Veg. Dinner & Snacks.</li>
                            <li>Buffet Breakfast (Fixed Menu).</li>
                        </ul>
                    </div>

                    {/* <!-- Package 2 --> */}
                    <div className="package-card" data-aos="fade-up" data-aos-delay="200">
                        <h3>Special 2 Night Stay</h3>
                        <p className="package-price">Rs. 9550 / Couple (2 Nights Stay at Camp)</p>
                        <img src={p2} alt="Special 2 Night Stay" />
                        <ul className="package-details">
                            <li>Station Pickup and Drop.</li>
                            <li>Traditional welcome with Aarti & Tikka.</li>
                            <li>2 Nights Stay at Camp.</li>
                            <li>One Camel Safari in the evening safari on dunes.</li>
                            <li>One Jeep Safari on dunes.</li>
                            <li>Bonfire with cultural program in camp.</li>
                            <li>Buffet Veg. Dinner & Snacks.</li>
                            <li>Buffet Breakfast (Fixed Menu).</li>
                        </ul>
                    </div>

                    {/* <!-- Package 3 --> */}
                    <div className="package-card" data-aos="fade-up" data-aos-delay="400">
                        <h3>Golden City 2 Nights</h3>
                        <p className="package-price">Rs. 8500 / Couple (2 Nights Stay with Jaisalmer Sightseeing)</p>
                        <img src={p3} alt="Golden City 2 Nights" />
                        <ul className="package-details">
                            <li>Traditional welcome with Aarti & Tikka.</li>
                            <li>One Night Stay at Desert Pearl Camp.</li>
                            <li>One Night Stay at Sagar Guest House.</li>
                            <li>One Camel Safari in the evening safari on dunes.</li>
                            <li>One Jeep Safari on dunes.</li>
                            <li>Bonfire with cultural program in camp.</li>
                            <li>Buffet Veg. Dinner & Snacks.</li>
                            <li>Buffet Breakfast (Fixed Menu).</li>
                            <li>Explore Golden City with Guide.</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Contact Section */}
            <section id="contact-form" className="contact">
                <h2>Contact Us</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="email"
                            name="email"
                            placeholder="Your Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            name="phone"
                            placeholder="Your Phone Number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <textarea
                            name="message"
                            placeholder="Your Message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <button type="submit">Send Message</button>
                </form>
                <div className="contact-info">
                    <p>Phone: +91 8094651274</p>
                    <p>Email: info@desertpearlresort.com</p>
                </div>
            </section>
            {/* Footer */}
            <Footer />
        </div>
    );
};

export default LandingPage;
